import {FormFieldEvent, FormFieldModel, FormService, WidgetComponent} from "@alfresco/adf-core";
import {Component, OnInit} from "@angular/core";
import {AppStateService} from "app/services/state.service";
import {FormFieldValidator} from "@alfresco/adf-core";

@Component({
    selector: "ccid-widget",
    templateUrl: "./ccidLookUp.component.html",
    styleUrls: ["./ccidLookUp.component.scss"]
})
export class CCIDWidgetComponent extends WidgetComponent implements OnInit {
    userName: string = "";
    fieldValidator: CCIDFieldValidator;

    constructor(private appStateService: AppStateService, public formService: FormService) {
        super(formService);
    }

    ngOnInit(): void {
        this.fieldValidator = new CCIDFieldValidator(this.userName)
        this.field.form.fieldValidators.push(this.fieldValidator);
        if (this.field.value) {
            this.setUserNameById();
        }
    }

    ccidOnChange(field: FormFieldModel) {
        this.userName = "";
        const formFieldValueChangedEvent = new FormFieldEvent(field.form, field);
        this.formService.formFieldValueChanged.next(formFieldValueChangedEvent);
        this.onFieldChanged(field);
    }

    onKey(event: any) {
        if (event.key === "Enter") {
            this.setUserNameById();
        }
    }

    onFocusOut() {
        this.setUserNameById();
    }

    private setUserNameById() {
        if (this.field.value) {
            this.field.value = this.field.value.toLowerCase();
            this.appStateService.callECMApi("/people/" + this.field.value, "GET").then(
                v => {
                    this.userName = v?.entry?.displayName;
                    this.fieldValidator.username = this.userName;
                    this.field.validationSummary.message = "";
                    this.field.validate();
                    this.field.form.validateForm();
                },
                e => {
                    this.userName = "";
                    this.field.validationSummary.message = `Invalid CCID ${this.field.value}`;
                    this.field.markAsInvalid();
                    this.field.form.markAsInvalid();
                    this.fieldValidator.username = "";
                }
            );   
        } else {
            if (this.isRequired()) {
                this.field.markAsInvalid();
                this.field.form.markAsInvalid();
                this.fieldValidator.username = "";
            }
        }
    }
}

class CCIDFieldValidator implements FormFieldValidator {
    constructor(public username: string) {}

    isSupported(field: FormFieldModel): boolean {
        return field && field.type === "ccid_look_up";
    }

    validate(field: FormFieldModel): boolean {
        if (this.isSupported(field) && field.required) {
            if (this.username === "") {
                return false;
            }

            if (field?.value === null) {
                return false;
            }
        }

        if (this.isSupported(field) && field?.value && this.username === "") {
            return false;
        }
        return true;
    }
}